import { useEffect, useState } from 'react';
import { useStoreSettings } from 'service/hooks';
import { ProductSortingTypes } from '../../../../common/constants';

const useShouldSortProductsByLang = (lang: string): boolean => {
  const { data: storeSettings } = useStoreSettings({});
  const [shouldSortProductsByLanguage, setShouldSortProductsByLanguage] = useState(false);
  useEffect(() => {
    const shouldSortProductsByLang = [
      ProductSortingTypes.ASCENDING_TITLE,
      ProductSortingTypes.DESCENDING_TITLE,
    ].includes(storeSettings?.setting?.menuItemSorting);
    setShouldSortProductsByLanguage(shouldSortProductsByLang);
  }, [lang, storeSettings]);
  return shouldSortProductsByLanguage;
};

export default useShouldSortProductsByLang;
