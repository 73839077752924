import { useQuery } from '../../utils';
import { storeSettingsQuery } from '../../queries/layout';
import { QueryHookResponse, Setting, Store } from '../../types';
import ServiceConfigs from '../../config';

export enum ProductSortingTypes {
  CUSTOM = 'custom',
  ASCENDING_TITLE = 'asc_title',
  DESCENDING_TITLE = 'desc_title',
  ASCENDING_CREATION = 'asc_creation',
  DESCENDING_CREATION = 'desc_creation',
}

type MenuItemSortingType = {
  setting: Setting & {
    menuItemSorting: ProductSortingTypes;
  };
};

type UseStoreSettings = ({ initialData }?: { initialData?: Store }) => QueryHookResponse<Store & MenuItemSortingType>;

const useStoreSettings: UseStoreSettings = ({ initialData } = {}) =>
  useQuery({
    query: storeSettingsQuery,
    variables: { subdomain: ServiceConfigs.getSubDomain() },
    handler: (res: any) => res.store,
    config: { initialData },
  });

export default useStoreSettings;
