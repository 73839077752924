import styled from 'styled-components';

export const StyledCartItemsCount = styled.div`
  padding-top: ${({ theme }) => `calc(${theme.space[1]}px/2)`};
  width: ${({ theme }) => `${theme.space[6]}px`};
  height: ${({ theme }) => `${theme.space[6]}px`};
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => `${theme.space[10]}px`};
`;

export const StyledOldPrice = styled.div`
  text-decoration: line-through;
  color: ${({ theme }) => theme.color.disabled};
`;

export const StyledActionButtonPrefix = styled.div`
  display: flex;
  align-items: center;
`;
