import React, { useState } from 'react';
import { Typography, Button, useTheme as useZacTheme } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';
import { XIcon } from '@zydalabs/zac-icons-react';
import { StyledActionSheetWrapper } from 'common/styles';
import { LANGUAGE_AR } from 'common/constants';
import { usePersistedShowDyma } from 'common/hooks';
import { StyledHeaderWrapper, StyledActionSheet, StyledBodyWrapper } from './styles';

type FixedFooterType = {
  headerTitle?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  closePermanently?: boolean;
};

const FixedFooter: React.FC<FixedFooterType> = ({ closePermanently = false, headerTitle, children, icon }) => {
  const handleChildClosePrevention = e => {
    e.stopPropagation();
  };

  const isHeaderShown = !!headerTitle;
  const { colors }: any = useZacTheme();
  const { lang } = useTranslation();
  const isArabic = lang === LANGUAGE_AR;
  const [hideDyma, setHideDyma] = usePersistedShowDyma();
  const [isOpen, setIsOpen] = useState(true);

  const toggleFooter = () => {
    if (closePermanently) {
      setHideDyma(!hideDyma);
    }
    setIsOpen(!isOpen);
  };

  return (
    <StyledActionSheetWrapper>
      <StyledActionSheet
        isDymaBanner
        isOpen={closePermanently ? hideDyma : isOpen}
        isArabic={isArabic}
        onClick={handleChildClosePrevention}
        isHeaderShown={isHeaderShown}
      >
        <Button endIcon={<XIcon color={colors?.positive.tertiary} />} onClick={toggleFooter} variant="ghost" size="small" />
        {isHeaderShown && (
          <StyledHeaderWrapper>
            {icon}
            <Typography variant="element14" mx={10}>
              {headerTitle}
            </Typography>
          </StyledHeaderWrapper>
        )}
        <StyledBodyWrapper>{children}</StyledBodyWrapper>
      </StyledActionSheet>
    </StyledActionSheetWrapper>
  );
};

export default FixedFooter;
