import { gql } from 'graphql-request';

const storeSettingsQuery = gql`
  query ($subdomain: String!) {
    store(subdomain: $subdomain) {
      id
      defaultProductPrepTime
      scheduleInfo {
        interval
        maxTimePeriod
      }
      activeDymaCashbackProgram {
        id
      }
      setting {
        scheduleOrdersEnabled
        menuItemSorting
        showAppBanner
        sendAsGiftEnabled
        feastEnabled
        deliveryVatInclusive
      }
    }
  }
`;

export default storeSettingsQuery;
