import React from 'react';

const IcDyma: React.FC = () => (<svg width="48" height="48" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect y="0.5" width="31" height="31" rx="4" fill="white" />
  <rect y="0.5" width="31" height="31" rx="4" fill="#3DFFC4" />
  <g clipPath="url(#clip0_5153_84865)">
    <path d="M8.45574 15.9293C8.45574 15.256 8.04948 14.6839 7.21069 14.6839C6.44364 14.6839 5.98331 15.214 5.98331 15.9293C5.98331 16.6446 6.44364 17.1745 7.21069 17.1745C8.04948 17.1745 8.45574 16.6022 8.45574 15.9293ZM9.39409 12V17.89H8.41962V17.3599C8.15787 17.7554 7.58031 17.991 7.06617 17.991C5.71273 17.9906 5 17.0819 5 15.9293C5 14.7768 5.78498 13.8678 7.0932 13.8678C7.65257 13.8678 8.15787 14.1034 8.41962 14.4988V12H9.39409Z" fill="#30376A" />
    <path d="M14.0097 13.9689V18.1491C14.0097 18.7633 13.8114 19.2261 13.4595 19.5458C13.1166 19.8403 12.6295 20.0002 12.0338 20.0002C11.4115 20.0002 10.9241 19.8572 10.5904 19.5962C10.2925 19.3523 10.1301 19.0072 10.1212 18.5782H11.1141C11.1591 19.0074 11.3847 19.243 12.0163 19.243C12.603 19.243 13.0269 18.9148 13.0269 18.0818V17.3427C12.7652 17.7635 12.296 17.9906 11.6735 17.9906C10.6361 17.9906 10.1217 17.3005 10.1217 16.3583V13.9689H11.105V16.1647C11.105 16.7454 11.3127 17.1827 12.0163 17.1827C12.702 17.1827 13.0269 16.6696 13.0269 15.9965V13.9689H14.0097Z" fill="#30376A" />
    <path d="M14.7821 13.9689H15.7657V14.5157C15.9461 14.1538 16.3882 13.8678 16.9385 13.8678C17.7055 13.8678 18.0845 14.1793 18.2649 14.5661C18.4632 14.1708 18.9597 13.8678 19.5552 13.8678C20.8184 13.8678 21.0711 14.6755 21.0711 15.4075V17.89H20.0875V15.5842C20.0875 14.9363 19.8167 14.6503 19.3025 14.6503C18.8061 14.6503 18.4182 14.8774 18.4182 15.8115V17.89H17.4347V15.5757C17.4347 14.928 17.182 14.6503 16.6679 14.6503C16.1717 14.6503 15.7657 14.8774 15.7657 15.8115V17.89H14.7821V13.9689Z" fill="#30376A" />
    <path d="M25.0617 15.9293C25.0617 15.256 24.6557 14.6839 23.8166 14.6839C23.0499 14.6839 22.5895 15.214 22.5895 15.9293C22.5895 16.6446 23.0499 17.1745 23.8166 17.1745C24.6557 17.1745 25.0617 16.6022 25.0617 15.9293ZM21.606 15.9293C21.606 14.7765 22.3912 13.8678 23.6994 13.8678C24.2588 13.8678 24.7638 14.1034 25.0256 14.499V13.9687H26V17.89H25.0256V17.3599C24.7638 17.7554 24.1865 17.991 23.6721 17.991C22.3189 17.991 21.606 17.0823 21.606 15.9298" fill="#30376A" />
  </g>
  <defs>
    <clipPath id="clip0_5153_84865">
      <rect width="21" height="8" fill="white" transform="translate(5 12)" />
    </clipPath>
  </defs>
</svg>);

export default IcDyma;
