import React from 'react';
import { Button, Tag, Typography, useTheme } from '@zydalabs/zac-react';
import { isNil } from 'ramda';
import useTranslation from 'next-translate/useTranslation';

import { useGlobalLoadingCart, usePersistedCartData, usePersistedCountryDetails } from 'common/hooks';
import { useCartSummary } from 'service/hooks';
import { useHandleCheckoutRedirection } from 'modules/checkoutModule/hooks';
import { changeRoute, localizeCurrency, localizeNumber } from 'common/utils';
import { StyledStickyButton } from 'common/styles';
import { StyledOldPrice } from './style';

const ActionButton: React.FC = () => {
  const [persistedCountryDetails] = usePersistedCountryDetails();
  const [cartData] = usePersistedCartData();
  const { t, lang } = useTranslation('home');
  const { colors } = useTheme();
  const { isLoading: isRoutingLoading, route } = useHandleCheckoutRedirection();

  const [isLoadingCart] = useGlobalLoadingCart();

  const { data: cartSummary, isLoading: isLoadingCartSummary } = useCartSummary({
    ...cartData,
  });

  const { currency } = persistedCountryDetails || {};
  const { cartItemsCount, total, discountedAmount, voucherAmount } = cartSummary || {};

  const isCartHasItems = !isNil(cartItemsCount) && cartItemsCount !== 0 && !isLoadingCartSummary;

  const isLoading = isLoadingCart || isLoadingCartSummary || isRoutingLoading;

  const totalBeforeDiscount = total + voucherAmount + discountedAmount;

  return (
    <>
      {(isCartHasItems || (cartData?.cartId && isLoading)) && (
        <StyledStickyButton>
          <Button
            isFluid
            isLoading={isLoading}
            onClick={() => changeRoute(route)}
            startIcon={cartItemsCount > 0 && <Tag color="accent" inner={{ text: localizeNumber(lang, cartItemsCount) }} />}
            text={t('reviewOrder')}
            endIcon={
              !isNil(total) &&
              !isNil(totalBeforeDiscount) &&
              !isNil(currency) && (
                <div>
                  <Typography variant="element14" color={colors.negative.primary}>
                    {localizeCurrency(lang, { amount: total, currency: currency.title_en })}
                  </Typography>
                  {totalBeforeDiscount !== total && (
                    <StyledOldPrice>
                      <Typography variant="element12" color={colors.negative.secondary}>
                        {localizeCurrency(lang, { amount: totalBeforeDiscount, currency: currency.title_en })}
                      </Typography>
                    </StyledOldPrice>
                  )}
                </div>
              )
            }
          />
        </StyledStickyButton>
      )}
    </>
  );
};

export default ActionButton;
