import React from 'react';
import { usePersistedCartData } from 'common/hooks';
import { isNil } from 'ramda';
import { Button } from '@zydalabs/zac-react';
import IcDyma from 'common/icons/IcDyma';
import { FixedFooter } from 'common/components';
import { useStoreSettings } from 'service/hooks';
import useTranslation from 'next-translate/useTranslation';
import ServiceConfigs from 'service/config';
import { StyledBoxWrapper } from 'common/styles';
import { RenderingRegistry } from 'common/lib/registries';
import ActionButton from '../ActionButton';
import { useCartSummary } from '../../../../service/hooks';

const FixedButton: React.FC = () => {
  const [cartData] = usePersistedCartData();
  const { data: cartSummary, isLoading: isLoadingCartSummary } = useCartSummary({
    ...cartData,
  });
  const { cartItemsCount } = cartSummary || {};
  const { t } = useTranslation('layout');
  const isCartHasItems = !isNil(cartItemsCount) && cartItemsCount !== 0 && !isLoadingCartSummary;
  const { data: storeSettings } = useStoreSettings();
  const { setting, activeDymaCashbackProgram } = storeSettings || {};
  const subdomain = ServiceConfigs.getSubDomain();
  const isManualOrder = RenderingRegistry.getIsManualOrder();

  return isCartHasItems ? (
    <ActionButton />
  ) : (
    !isManualOrder && (
      <StyledBoxWrapper>
        {setting?.showAppBanner && (
          <FixedFooter
            closePermanently
            headerTitle={t(activeDymaCashbackProgram ? 'dymaCashback' : 'dymaFasterExperience')}
            icon={<IcDyma />}
          >
            <Button
              rounded
              onClick={() => {
                window.open(`https://dyma.app/store/${subdomain}`, '_blank');
              }}
              text={t(activeDymaCashbackProgram ? 'trackWithDymaCashback' : 'trackWithDymaFasterExperience')}
              isFluid
            />
          </FixedFooter>
        )}
      </StyledBoxWrapper>
    )
  );
};
export default FixedButton;
