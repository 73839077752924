import styled, { css } from 'styled-components';

export const StyledActionSheet = styled.div<{
  isHeaderShown: boolean | undefined;
  isOpen: boolean;
  isArabic: boolean;
  isDymaBanner: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: inherit;
  background: ${({ theme }) => theme.color.white};
  max-height: 70vh;

  ${({ theme, isHeaderShown }) =>
    !isHeaderShown &&
    `
  padding-top: ${theme.space[4]}px;
  height: 88vh;
  max-height: 88vh;
  `}
  padding: ${({ theme }) => theme.space[7]}px ${({ theme }) => theme.space[4]}px;
  padding-bottom: ${({ theme }) => theme.space[5]}px;
  border-top-left-radius: ${({ theme }) => theme.space[3] / 2}px;
  border-top-right-radius: ${({ theme }) => theme.space[3] / 2}px;
  overflow: auto;
  overscroll-behavior-y: contain;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0px -4px 8px -4px rgba(67, 178, 166, 0.08), 0px -2px 4px -2px rgba(27, 32, 50, 0.04);
  border-radius: 20px 20px 0px 0px;
  > button {
    position: absolute;
    ${({ isArabic }) =>
      isArabic
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `};
    top: -4px;
  }
  > button:focus, button:active, button:hover {
    background-color: transparent!important;
    border-color: transparent!important;
    --tw-ring-color:transparent!important;
  }
  }
  ${({ isDymaBanner }) =>
    isDymaBanner &&
    css`
      z-index: 1;
    `}
  ${({ isOpen }) =>
    isOpen
      ? css`
          visibility: visible;
          opacity: 1;
          transition: opacity 300ms;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 300ms, opacity 300ms;
        `};
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space[4]}px;
  ${({ theme }) => ` 
    > label {
      font-weight: ${theme.fontWeights.semiBold};
    }
  `}
`;

export const StyledBodyWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  button {
    background-color: #30376a !important;
  }
  button:focus,
  button:active,
  button:hover {
    background-color: #30376a !important;
    border-color: #30376a !important;
    --tw-ring-color: #30376a !important;
  }
`;
